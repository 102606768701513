.notarialActContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerNotarialAct {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleNotarialAct {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonyNotarialAct {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}


/* SECTION BOXES */
.wrapperNotarialAct {
    width: 1066px;
    margin-top: 12vh;
    display: flex;
    justify-content: center;
    position: relative;

    .tile1x1img {
        width: 170px;
        height: 170px;
    }

    .tile1x2 {
        width: 326px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    /* Akty notarialne w szt. */
    .box1notarialAct {
        left: 0px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        .notarialActBoxTitle {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 30%;
            justify-content: space-between;

            .notarialActBoxValue {
                color: var(--fourth);
            }
        }

        .notarialActBoxTitle:nth-of-type(2) {
            height: 45%;
        }

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    /* img box left 1  */
    .box2notarialAct {
        left: 350px;
        background-image: url('../../assets/reports/colonyBox4.png');
    }

    /* Akty not. umówione */
    .box3notarialAct {
        left: 544px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        .notarialActBoxTitle {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 30%;
            justify-content: space-between;

            .notarialActBoxValue {
                color: var(--fourth);
            }
        }

        .notarialActBoxTitle:nth-of-type(2) {
            height: 45%;
        }

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    /* img box right 1 */
    .box4notarialAct {
        left: 894px;
        background-image: url('../../assets/reports/colonyBox5.png');
    }

    /* img box left 2 */
    .box5notarialAct {
        left: 0px;
        top: 194px;
        background-image: url('../../assets/reports/colonyBox5.png');
    }

    /* Akty not. do umówienia */
    .box6notarialAct {
        left: 194px;
        top: 194px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        .notarialActBoxTitle {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 30%;
            justify-content: space-between;

            .notarialActBoxValue {
                color: var(--fourth);
            }
        }

        .notarialActBoxTitle:nth-of-type(2) {
            height: 45%;
        }

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }
    }

    /* img box right 2 */
    .box7notarialAct {
        left: 544px;
        top: 194px;
        background-image: url('../../assets/reports/colonyBox4.png');
    }

    /* Odbyte spotkania */
    .box8notarialAct {
        left: 738px;
        top: 194px;
        width: 326px;

        .notarialActMeetingsTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;

            img {
                margin-right: 16px;
            }
        }

        .notarialActMeetingsValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div {
                font-size: 40px;
            }

            .notarialActBoxValue {
                font-size: 20px;
                color: var(--fourth);
            }

            .notarialActMeetingsValueStatus {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: var(--second);
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }
}