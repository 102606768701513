.funnelGlobalContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

}

/* SECTION HEADER */
#headerGlobalFunnel {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleGlobalFunnel {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonyGlobalFunnel {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}

/* SECTION BOXES */
.wrapperGlobalFunnel {
    width: 1380px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    position: relative;

    /* tile size declaration */
    .tile1x3long {
        width: 326px;
        height: 128px;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        padding: 24px 20px 19px 24px;

        .rightBoxContainer {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            z-index: 2;

            .lineYDashedPos {
                position: absolute;
                left: 39px;
                width: 1px;
                height: 16px;
            }

            .valueLabel {
                font-size: 13px;
                padding-left: 12px;
                line-height: 125%;
                color: var(--fourth);
            }

            .valueLabel:nth-of-type(2) {
                margin-top: 18px;
            }

            .imgSizeM:nth-of-type(2) {
                margin-top: 16px;
            }
        }

        .percentageContainer {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            height: 100%;
            justify-content: flex-end;
            z-index: 2;

            .percentageValue {
                font-size: 32px;
                margin-bottom: 3px;
            }
        }

    }

    /* Box Main Global Funnel */
    .boxMain {
        position: absolute;
        width: 946px;
        height: 560px;
        left: 42px;

        .boxMainData {
            position: absolute;
            width: 866px;
            height: 408px;
            left: 32px;

            .pContainer {
                display: flex;
                position: absolute;
                height: 44px;
                z-index: 1;

                .singleFunnel {
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                }

                .positionElementsFunnel {
                    margin-left: 10px;
                    margin-right: 16px;
                }

                .pText {
                    width: 117px;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: right;
                    padding-right: 18px;
                    align-self: center
                }

                .pColor {
                    color: black;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 130%;
                    // letter-spacing: .5px;
                }

                .pPercentageBox {
                    position: absolute;
                    width: 69px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 4px;
                    color: black;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 110%;
                    background: rgba(255, 255, 255, 0.7);
                }

                // .pAllPercentage {
                //     position: absolute;
                // }

                .p2Percentage {
                    left: 656px;
                }

                .p3Percentage {
                    left: 614px;
                }

                .p4Percentage {
                    left: 572px;
                }

                .p5Percentage {
                    left: 530px;
                }

                .p6Percentage {
                    left: 488px;
                }

                .p7Percentage {
                    left: 446px;
                }

                .p8Percentage {
                    left: 402px;
                }
            }


            .p1container {
                width: 777px;

                .singleFunnel {
                    background-color: var(--eighth);
                    width: 660px;
                }
            }

            .p2container {
                width: 743px;
                top: 52px;

                .pColor {
                    background-color: var(--ninth);
                    width: 624px;
                }
            }

            .p3container {
                width: 699px;
                top: 104px;

                .pColor {
                    background-color: var(--tenth);
                    width: 582px;
                }
            }

            .p4container {
                width: 656px;
                top: 156px;

                .pColor {
                    background-color: #FFDC25;
                    width: 540px;
                }
            }

            .p5container {
                width: 614px;
                top: 208px;

                .pColor {
                    background-color: var(--seventh);
                    width: 498px;
                }
            }

            .p6container {
                width: 571px;
                top: 260px;

                .pColor {
                    display: flex;
                    align-items: center;
                    width: 456px;
                    border-radius: 4px;
                    background-color: var(--sixth);
                }
            }

            .p7container {
                width: 532;
                top: 312px;

                .pColor {
                    background-color: var(--fifth);
                    width: 414px;

                    .imgSizeM {
                        margin-left: 10px;
                        margin-right: 16px;
                    }
                }
            }

            .p8container {
                width: 491px;
                top: 364px;

                .pColor {
                    background-color: var(--third);
                    width: 372px;
                }
            }
        }
    }

    .funnelRightBoxes {
        position: absolute;
        display: flex;
        justify-content: center;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0) 85.52%);
        border-radius: 3.69179px;
        font-size: 16px;
        font-weight: 600;
        writing-mode: vertical-rl;
        line-height: 130%;
        padding-right: 31px;
    }

    .funnelMarketingBox {
        left: 610px;
        width: 255px;
        height: 148px;
    }

    .funnelDszBox {
        left: 520px;
        top: 156px;
        width: 344px;
        height: 96px;
    }

    .funnelDstBox {
        left: 430px;
        top: 260px;
        width: 435px;
        height: 96px;
    }

    .funnelDuBox {
        left: 385px;
        top: 364px;
        width: 480px;
        height: 44px;
    }

    /* Box p4p8 */
    .box1 {
        left: 1012px;

        .box1ShadowContainer {
            position: absolute;
            left: 0;
            top: 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            z-index: 1;

            .box1Shadow {
                position: absolute;
                width: 88px;
                height: 66.26px;
                left: 239px;
                top: 62px;
                background: rgba(255, 255, 255, 0.75);
                filter: blur(200px);
                border-radius: 16px;
                z-index: 0;
            }

            .box1ShadowMask {
                position: absolute;
                width: 326px;
                height: 128px;
                left: 0px;
                top: 0px;
                background: #222222;
                border-radius: 16px;
                z-index: -1;
            }
        }
    }

    /* Box p6p8 */
    .box2 {
        left: 1012px;
        top: 144px;

        .box2ShadowContainer {
            position: absolute;
            left: 0;
            top: 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            z-index: 1;

            .box2Shadow {
                position: absolute;
                width: 88px;
                height: 66.26px;
                left: 239px;
                top: 62px;
                background: rgba(255, 255, 255, 0.75);
                filter: blur(200px);
                border-radius: 16px;
                z-index: 0;
            }

            .box2ShadowMask {
                position: absolute;
                width: 326px;
                height: 128px;
                left: 0px;
                top: 0px;
                background: #222222;
                border-radius: 16px;
                z-index: -1;
            }
        }
    }

    /* Box p5p7 */
    .box3 {
        left: 1012px;
        top: 288px;

        .box3ShadowContainer {
            position: absolute;
            left: 0;
            top: 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            z-index: 1;

            .box3Shadow {
                position: absolute;
                width: 88px;
                height: 66.26px;
                left: 239px;
                top: 62px;
                background: rgba(255, 255, 255, 0.75);
                filter: blur(200px);
                border-radius: 16px;
                z-index: 0;
            }

            .box3ShadowMask {
                position: absolute;
                width: 326px;
                height: 128px;
                left: 0px;
                top: 0px;
                background: #222222;
                border-radius: 16px;
                z-index: -1;
            }
        }
    }

    /* Box p4p6 */
    .box4 {
        left: 1012px;
        top: 432px;

        .box4ShadowContainer {
            position: absolute;
            left: 0;
            top: 0px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            z-index: 1;

            .box4Shadow {
                position: absolute;
                width: 88px;
                height: 66.26px;
                left: 239px;
                top: 62px;
                background: rgba(255, 255, 255, 0.75);
                filter: blur(200px);
                border-radius: 16px;
                z-index: 0;
            }

            .box4ShadowMask {
                position: absolute;
                width: 326px;
                height: 128px;
                left: 0px;
                top: 0px;
                background: #222222;
                border-radius: 16px;
                z-index: -1;
            }
        }
    }

}