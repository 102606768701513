@font-face {
    font-family: 'Gilroy-Medium';
    src: url('../fonts/gilroy-medium.otf');
}

* {
    // font-family: 'Gilroy-Medium';
    // font-family: Gilroy-Extrabold;
    color: white;
}


:root {
    --first: #171716;
    --second: #3EFF8B;
    --third: #62FF98;
    --fourth: #919191;
    --fifth: #99CE4E;
    --sixth: #C5D852;
    --seventh: #D3F430;
    --eighth: #DB4444;
    --ninth: #E87D47;
    --tenth: #E9B347;
    --eleventh: #ED254E;
    --twelfth: #FFDC25;
}

.inactive {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

/* size declaration of [img] */
.imgSizeM {
    width: 32px;
    height: 32px;
}

.imgSizeL {
    width: 44px;
    height: 44px;
}

// zmien na XL
.imgSizeXl {
    width: 56px;
    height: 56px;
}

/* tile size declaration */
.tile {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    position: absolute;
    background: #222222;
}