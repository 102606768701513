.resourcesContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerResources {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleResources {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonyResources {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}

/* SECTION BOXES */
.wrapperResources {
    width: 1380px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    position: relative;


    /* tile size declaration */
    .tile1x2long {
        width: 416px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    .tile2x2long {
        width: 416px;
        height: 364px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        line-height: 28.8px;
        padding: 32px;
    }

    .tile1x4long {
        width: 1296px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    /* Wartość sprzedanych działek */
    .box1resources {
        left: 42px;

        .resourcesBoxTitle {
            display: flex;

            img {
                margin-right: 24px;
            }
        }

        .resourcesBoxValue {
            align-self: flex-start;

            div>p.resourcesBoxValueSingle {
                font-size: 48px;
            }

            div>p>.resourceBoxValueInit {
                font-size: 36px;
                color: var(--fourth);
            }
        }
    }

    /* Wartość niesprzedanych działek */
    .box2resources {
        left: 482px;

        .resourcesBoxTitle {
            display: flex;

            img {
                margin-right: 24px;
            }
        }

        .resourcesBoxValue {
            align-self: flex-start;

            div>p.resourcesBoxValueSingle {
                font-size: 48px;
            }

            div>p>.resourceBoxValueInit {
                font-size: 36px;
                color: var(--fourth);
            }
        }
    }

    /* Magazyn działek */
    .box3resources {
        left: 922px;

        .resourcesBoxTitle {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin-right: 16px;
            }
        }

        .resourcesBoxValue {
            width: 100%;

            .resourceBoxValueSingle {
                font-size: 40px;

                .resourceBoxValueInit {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    /* Działki do sprzedania */
    .box4resources {
        left: 922px;
        top: 194px;

        .resourcesBoxTitle {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin-right: 16px;
            }
        }

        .resourcesBoxValue {
            width: 100%;

            .resourceBoxValueSingle {
                font-size: 40px;

                .resourceBoxValueInit {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    /* img box bottom */
    .box5resources {
        left: 42px;
        top: 388px;
        background-image: url('../../assets/reports/colonyBox6.png');
    }
}