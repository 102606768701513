.leadsContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerLeads {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleLeads {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonyLeads {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}

/* SECTION BOXES */
.wrapperLeads {
    width: 1380px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    position: relative;

    /* tile size declaration */
    .tile1x1 {
        width: 170px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        padding: 26px 20px 24px;
    }

    .tile1x2 {
        width: 326px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    .tile2x2 {
        width: 364px;
        height: 364px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        line-height: 28.8px;
        padding: 32px;
    }

    .tile1x2img {
        width: 364px;
        height: 170px;
    }

    .tile1x3img {
        width: 558px;
        height: 170px;
    }

    /* effects */
    .blurEffectOne {
        /* background-color: rgba(62, 255, 139, 0.75); */
        filter: blur(200px);
    }

    .blurEffectTwo {
        /* background-color: rgba(255, 255, 255, 0.75); */
        filter: blur(200px);
    }

    .blurEffectThree {
        background-color: var(--eleventh);
        filter: blur(200px);
    }

    /* Leady kwalifikowane - 2x2 */
    .box1 {
        left: 42px;

        .boxTitle {
            display: flex;
            flex-direction: row;

            .imgSizeXl {
                margin-right: 24px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div p:nth-of-type(1) {
                font-size: 72px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div .boxSmallValue {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .smallBox1 {
        position: absolute;
        width: 112px;
        height: 112px;
        left: 294px;
        top: 250px;
    }

    /* Leady kwalifikowane */
    .box2 {
        left: 430px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;
            }

            .boxSmallValue {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .smallBox2 {
        position: absolute;
        width: 88px;
        height: 88px;
        left: 668px;
        top: 82px;
    }

    /* img box right */
    .box3 {
        left: 780px;
        background-image: url('../../assets/reports/colonyBoxZi1.jpg');
    }

    /* Nowe leady */
    .box4 {
        left: 431px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }

            div:nth-of-type(2) {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .smallBox4 {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 677px;
        top: 284px;
    }

    /* CAC PPC */
    .box5 {
        left: 780px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            align-items: center;

            .imgSizeXl {
                margin-right: 24px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 72px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: var(--eleventh);
                    margin-right: 6px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .smallBox5 {
        position: absolute;
        width: 112px;
        height: 112px;
        left: 1032px;
        top: 446px;
        background-color: red;
    }

    /* Koszt kampanii */
    .box6 {
        left: 1170px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div {
                font-size: 28px;
            }
        }
    }

    .smallBox6 {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 1260px;
        top: 284px;
    }



    /* img box left */
    .box7 {
        left: 42px;
        top: 390px;
        background-image: url('../../assets/reports/colonyBoxZi2.jpg');
    }

    /* Wszystkie leady */
    .box8 {
        left: 430px;
        top: 390px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth)
                }
            }
        }
    }

    .smallBox8 {
        position: absolute;
        width: 88px;
        height: 88px;
        left: 668px;
        top: 472px;
    }



    /* Współczynnik CAC */
    .box9 {
        left: 1170px;
        top: 385px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div {
                font-size: 28px;
            }

            div:nth-of-type(2) {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    .smallBox9 {
        position: absolute;
        width: 80px;
        height: 80px;
        left: 1260px;
        top: 475px;
    }


}