@font-face {
    font-family:'Gilroy-Bold';
    src:url('/src/fonts/gilroy-bold.otf');
   }

#root {
    background-color: #384047;
}

#funnelId {
    font-family:'Gilroy-Bold';
}

.container {
    max-width: 1500px;
}

.boxContainer {
    height: 100vh;
    padding-top: 8vh;
    font-size: 22px;
    font-weight: bold;
}

.boxFunnel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    height: 60px;
    border: solid 1px black;
    padding-left: 30px;
}

.boxFunnelSmall {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black;
    height: 40px;
    border: solid 1px black;
    padding-left: 30px;
    font-size: 15px;
    background: rgb(237,208,50) !important;
    /* width: 40% !important; */
}

.p-box {
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* background-color: white; */
}

.p1-box {
    width: 80%;
    background: rgb(214,55,65);
}

.p2-box {
    width: 75%;
    background: rgb(224,94,62);
}

.p3-box {
    width: 70%;
    background: rgb(232,170,49);
}

.p4-box {
    width: 65% !important;
    background: rgb(237,208,50) !important;
}

.p4-box-small {
    width: 90% !important;
    background: rgb(237,208,50) !important;
}

.p5-box {
    width: 60%;
    background: rgb(211,200,42);
}

.p6-box {
    width: 55%;
    background: rgb(192,195,41);
}

.p6-box-small {
    width: 90% !important;
    background: rgb(192,195,41) !important;
}

.p7-box {
    width: 50%;
    background: rgb(130,201,39);
}

.p8-box {
    width: 45% !important;
    background: rgb(76,201,36) !important;
}

.p8-box-small {
    width: 90% !important;
    background: rgb(76,201,36) !important;
}

.percentageBox {
    color: black;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 20px;
    background-color: rgb(215,230,207);
    border: 1px solid rgb(196,191,61);
}

.percentageBoxSmall {
    color: black;
    font-size: 16px;
    font-weight: 600;
    padding: 3px 16px;
    background-color: rgb(215,230,207);
    border: 1px solid rgb(196,191,61);
}

.right {
    writing-mode: vertical-rl;
    /* text-orientation: mixed; */
}

.left {
    color: white;
    background-color: #384047;
}