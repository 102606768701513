.hrContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerHr {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleSale {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonySale {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
            font-size: 30px;
        }
    }
}


/* SECTION BOXES */
.wrapperHr {
    width: 1380px;
    margin-top: 70px;
    margin-left: -100px;
    display: flex;
    justify-content: center;
    position: relative;

    .titleCalendar {
        color: rgb(62, 255, 139);
        position: absolute;
        left: 42px;
        top: -50px;
    }


    .title2Calendar {
        position: absolute;
        // left: 740px;
        left: 520px;
        color: rgb(62, 255, 139);

        // left: 520px;
        // top: 420px;
        // top: 320px;
        top: 360px;
        font-size: 1.75rem;
    }

    .tile2x2hr {
        width: 450px;
        // height: 600px;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 24px;
        line-height: 28.8px;
        padding: 32px;
    }

    .tileRecruitment {
        width: 468px;
    }

    .tilebottom {
        // width: 700px;
        // width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        font-size: 24px;
        line-height: 28.8px;
        padding: 30px 32px 24px 32px;
    }

    .box1hr {
        left: 42px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            p {
                margin-left: 10px;
                margin-top: 11px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 48px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }


    .box2hr {
        left: 515px;
        width: 473px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            p {
                margin-left: 10px;
                margin-top: 11px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 48px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .box3hr {
        // left: 990px;
        left: 1012px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            p {
                margin-left: 10px;
                margin-top: 11px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 48px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .box4hr {
        // top: 770px;
        // left: 42px;
        // top: 470px;
        // top: 380px;
        // top: 330px;
        top: 415px;
        // left: 740px;
        left: 520px;
        // width: 473px;
        // left: 515px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            p {
                margin-left: 10px;
                margin-top: 11px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 48px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

}