.targetGlobalContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerGlobalTarget {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleGlobalTarget {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonyGlobalTarget {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}

/* SECTION BOXES */
.wrapperGlobalTarget {
    width: 1380px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    position: relative;

    /* tile size declaration */
    .tile1x1long {
        width: 196px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        line-height: 20px;
        padding: 26px 20px 24px;
    }

    .tile1x2long {
        width: 416px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    .tile1x5 {
        width: 856px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        line-height: 28.8px;
        padding: 32px;
    }

    /* Realizacja targetu */
    .box1 {
        left: 42px;

        .boxTitle {
            display: flex;
            align-self: flex-start;

            img {
                margin-right: 24px;
            }
        }

        .boxValue {
            display: flex;
            align-self: flex-end;
            margin-right: 19px;
            position: absolute;
            top: 80px;

            .flipContainer {
                overflow: hidden !important;
                width: 550px;

                span[aria-hidden=true]:nth-last-child(1) {
                    margin-bottom: -20px !important;
                    margin-left: 10px !important;
                    font-size: 38px;
                    color: var(--fourth);
                }

                span[aria-hidden=true]:nth-last-child(2) {
                    margin-bottom: -20px;
                    margin-left: -20px;
                    font-size: 38px;
                    color: var(--fourth);
                }

                span[aria-hidden=true]:nth-last-child(3n+3) {
                    margin-right: 40px;
                }

            }

            div>div {
                font-size: 72px;
                letter-spacing: 10px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                    letter-spacing: 0;
                }
            }
        }
    }

    .smallBox1 {}

    /* Aktualny wpływ */
    .box2 {
        left: 923px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;

            img {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            width: 100%;
            justify-content: space-between;

            div:nth-of-type(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    .smallBox2 {}

    /* Target */
    .box3 {
        left: 42px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth)
                }
            }
        }
    }

    .smallBox3 {}

    /* Target tygodniowy */
    .box4 {
        left: 482px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    .smallBox4 {}

    /* Wartość sprzedanych działek */
    .box5 {
        left: 922px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 27px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    .smallBox5 {}

    /* Wartość działek dostępnych w sprzedaży */
    .box6 {
        left: 1142px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 27px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    .smallBox6 {}

    /* Realizacja targetu */
    .box7 {
        left: 42px;
        top: 390px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;
            }
        }
    }

    .smallBox7 {}

    /* Realizacja targetu tygodniowego */
    .box8 {
        top: 194px;
        top: 390px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;
            }
        }
    }

    .smallBox8 {}

    /* Zadatki */
    .box9 {
        left: 922px;
        top: 385px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 28px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    /* Podpisane akty notarialne */
    .box10 {
        left: 1142px;
        top: 385px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 28px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }
        }
    }

    .smallBox10 {}
}