.saleContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* SECTION HEADER */
#headerSale {
    width: 100%;
    height: 100px;
    background-color: var(--first);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    border-bottom: 1px solid rgb(43, 42, 43);

    .titleSale {
        font-size: 32px;
        align-self: center;
        font-weight: 400;
    }

    .switchColonySale {
        width: 200px;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        div {
            margin-right: 20px;
        }
    }
}


/* SECTION BOXES */
.wrapperSale {
    width: 1380px;
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    position: relative;


    /* tile size declaration */
    .tile1x1img {
        width: 170px;
        height: 170px;
    }

    .tile1x2 {
        width: 326px;
        height: 170px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 20px;
        line-height: 24px;
        padding: 26px 30px 24px 20px;
    }

    .tile2x2 {
        width: 364px;
        height: 364px;
        flex-direction: column;
        justify-content: space-between;
        font-size: 24px;
        line-height: 28.8px;
        padding: 32px;
    }

    /* Wartość sprzedaży w zadatkach */
    .box1sale {
        left: 42px;

        .boxTitle {
            display: flex;
            flex-direction: row;

            .imgSizeXl {
                margin-right: 24px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            div>p {
                font-size: 48px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    /* Akty notarialne w szt. */
    .box2sale {
        left: 430px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }

        .boxTitle {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 30%;
        }

        .boxValue {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 45%;

            div>span {
                font-size: 20px;
                color: var(--fourth);
            }

            div:nth-of-type(2) span {
                font-size: 20px;
                color: var(--fourth);
            }
        }
    }

    /* img box right 1  */
    .box3sale {
        left: 780px;
        background-image: url('../../assets/reports/colonyBox4.png');
    }

    /* Podpisane umowy rezerwacyjne */
    .box4sale {
        left: 974px;
        width: 364px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-type(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }

            div:nth-of-type(2) {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    /* img box left 1 */
    .box5sale {
        left: 431px;
        top: 194px;
        background-image: url('../../assets/reports/colonyBox5.png');
    }

    /* Akty not. do umówienia */
    .box6sale {
        left: 625px;
        top: 194px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        .boxTitle {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 30%;

        }

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }

        .boxValue {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 45%;

            div>span {
                font-size: 20px;
                color: var(--fourth);
            }
        }
    }

    /* Odbyte spotkania */
    .box7sale {
        left: 974px;
        top: 194px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;

            .imgSizeXl {
                margin-right: 24px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;

            p {
                font-size: 72px;

                span {
                    font-size: 36px;
                    color: var(--fourth);
                }
            }

            div>div {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: var(--eleventh);
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    /* Zadatki*/
    .box8sale {
        left: 42px;
        top: 390px;
        width: 364px;

        .boxTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            .imgSizeL {
                margin-right: 16px;
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;

            div:nth-of-child(1) {
                font-size: 40px;

                span {
                    font-size: 20px;
                    color: var(--fourth);
                }
            }

            .smallValue {
                display: flex;
                flex-direction: row;
                align-items: center;

                p {
                    font-size: 20px;
                    color: #3EFF8B;
                    margin-right: 6px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    /* Akty not. do umówienia */
    .box9sale {
        left: 430px;
        top: 390px;
        padding: 20px 15px;
        font-size: 16px;
        line-height: 0.7em;

        .boxTitle {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 30%;
        }

        div:nth-child(2) {
            font-size: 20px;
            line-height: 1.2em;
        }

        .boxValue {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 45%;

            div>span {
                font-size: 20px;
                color: var(--fourth);
            }
        }
    }

    /* img box right 2 */
    .box10sale {
        left: 781px;
        top: 390px;
        background-image: url('../../assets/reports/colonyBox4.png');
    }
}