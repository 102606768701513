@import "../global.scss";

.telephoneContactContainer {
    font-family: 'Gilroy-Medium';
    height: 100vh;
    background-color: var(--first);
    background-image: url('../../assets/reports/background_vector.png');
    display: flex;
    flex-direction: column;
    align-items: center;


    /* SECTION HEADER */
    #headerTelephoneContact {
        width: 100%;
        height: 100px;
        background-color: var(--first);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 50px;
        border-bottom: 1px solid rgb(43, 42, 43);

        .titleTelephoneContact {
            font-size: 32px;
            align-self: center;
            font-weight: 400;
        }

        .switchColony {
            width: 200px;
            font-size: 24px;
            font-weight: bold;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            div {
                margin-right: 20px;
            }
        }
    }

    /* SECTION BOXES */
    .wrapperTel {
        width: 1380px;
        margin-top: 8vh;
        display: flex;
        justify-content: center;
        position: relative;

        /* tile size declaration */
        .tile1x1 {
            width: 170px;
            height: 170px;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;
            line-height: 20px;
            padding: 26px 20px 24px;
        }

        .tile1x2 {
            width: 326px;
            height: 170px;
            flex-direction: column;
            justify-content: space-between;
            font-size: 20px;
            line-height: 24px;
            padding: 26px 30px 24px 20px;
        }

        .tile2x2 {
            width: 364px;
            height: 364px;
            flex-direction: column;
            justify-content: space-between;
            font-size: 24px;
            line-height: 28.8px;
            padding: 32px;
        }

        .tile1x2img {
            width: 364px;
            height: 170px;
        }

        /* effects */
        .telBlurEffectOne {
            /* background-color: rgba(62, 255, 139, 0.75); */
            filter: blur(200px);
        }

        .telBlurEffectTwo {
            /* background-color: rgba(255, 255, 255, 0.75); */
            filter: blur(200px);
        }

        .telBlurEffectThree {
            background-color: var(--eleventh);
            filter: blur(200px);
        }

        /* Wykonane nowe telefony */
        .box1tel {
            left: 42px;

            .boxTitle {
                display: flex;
                flex-direction: row;

                .imgSizeXl {
                    margin-right: 24px;
                }
            }

            .boxValue {

                div p {
                    font-size: 72px;

                    span {
                        font-size: 36px;
                        color: var(--fourth);
                    }
                }

                div div {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-size: 20px;
                        color: #3EFF8B;
                        margin-right: 6px;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .boxValue {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
        }

        .smallBox1tel {
            position: absolute;
            width: 112px;
            height: 112px;
            left: 294px;
            top: 250px;
        }

        /* Nowe umówione spotkania */
        .box2tel {
            left: 430px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                width: 100%;

                .imgSizeL {
                    margin-right: 16px;
                }
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div:nth-of-type(1) {
                    font-size: 40px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }

                div:nth-of-type(2) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-size: 20px;
                        color: #3EFF8B;
                        margin-right: 6px;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .smallBox2tel {
            position: absolute;
            width: 88px;
            height: 88px;
            left: 668px;
            top: 82px;
        }

        /* Odbyte spotkania */
        .box3tel {
            left: 780px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                width: 100%;
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div {
                    font-size: 28px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }
            }
        }

        .smallBox3tel {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 871px;
            top: 90px;
        }

        /* img box right */
        .box4tel {
            left: 974px;
            background-image: url('../../assets/reports/colonyBox3.png');
        }

        /* Wykonane telefony */
        .box5tel {
            left: 431px;
            top: 194px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                width: 100%;
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div {
                    font-size: 28px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }
            }
        }

        .smallBox5tel {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 521px;
            top: 284px;
        }

        /* Wszystkie odbyte spotkania */
        .box6tel {
            left: 625px;
            top: 194px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                width: 100%;

                .imgSizeL {
                    margin-right: 24px;
                }
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div:nth-of-type(1) {
                    font-size: 40px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }
            }
        }

        .smallBox6tel {
            position: absolute;
            width: 88px;
            height: 88px;
            left: 863px;
            top: 276px;
        }

        /* Sprzedaż w zadatkach */
        .box7tel {
            left: 974px;
            top: 194px;
        }

        .smallBox7tel {
            position: absolute;
            width: 112px;
            height: 112px;
            left: 1226px;
            top: 446px;
        }

        /* img box left */
        .box8tel {
            left: 42px;
            top: 390px;
            background-image: url('../../assets/reports/colonyBox2.png');
        }

        /* Leady kwalifikowane */
        .box9tel {
            left: 430px;
            top: 390px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                .imgSizeL {
                    margin-right: 16px;
                }
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div:nth-of-type(1) {
                    font-size: 40px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }

                div:nth-of-type(2) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-size: 20px;
                        color: #3EFF8B;
                        margin-right: 6px;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .smallBox9tel {
            position: absolute;
            width: 88px;
            height: 88px;
            left: 668px;
            top: 472px;
        }

        /* Wysłane oferty */
        .box10tel {
            left: 781px;
            top: 390px;

            .boxTitle {
                display: flex;
                flex-direction: row;
                width: 100%;
            }

            .boxValue {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                width: 100%;

                div {
                    font-size: 28px;

                    span {
                        font-size: 20px;
                        color: var(--fourth);
                    }
                }
            }
        }

        .smallBox10tel {
            position: absolute;
            width: 80px;
            height: 80px;
            left: 871px;
            top: 480px;
        }
    }

}