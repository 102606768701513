@font-face {
  font-family: 'Gilroy-Extrabold';
  src: url('/src/fonts/gilroy-extrabold.otf');
}

* {
  color: white;
}

#number {
  width: 100%;
  height: 190px;
  display: flex;
  overflow: hidden;
  line-height: 190px;
  margin: 150px 0 100px 0;
}

#backgroundId {
  font-family: 'Gilroy-Extrabold';
  background-image: url('../../assets/counterBackground.jpeg');
  height: 100vh;
  background-position: center center;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
}

#backgroundId>div.d-flex {
  height: 80vh;
}

#backgroundId>div.d-flex>div.container {
  max-width: 1680px;
  margin-top: 120px;
}

.title {
  background-color: #24AAE1;
  padding: .75em;
  font-size: 1.65em;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.title.break {
  margin-left: 10px;
}

.smaller {
  font-size: 1.2rem;
  margin-left: -70px;
}

/* custom styling  */

span {
  overflow: visible !important;
}

span[aria-hidden=true]:nth-last-child(1) {
  margin-bottom: -20px !important;
  margin-left: 20px !important;
}

span[aria-hidden=true]:nth-last-child(2) {
  margin-bottom: -20px;
  margin-left: -20px;
}

span[aria-hidden=true]:nth-last-child(3n+3) {
  margin-right: 80px;
}

span.topValue {
  font-size: 200px;
  font-weight: 700;
}

.hidden {
  display: none;
}